<template>
  <b-row>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-md-6 col-lg-4'" v-if="Object.keys(audienceData.type).length > 0">
      <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <b-card-title>{{$t('creator.typeUser')}}</b-card-title>
        </b-card-header>
        <b-card-body>
          <component :is="apex_charts" type="bar" height="300" :options="chartOptions.type" :series="series.type"></component>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-md-6 col-lg-4'" v-if="audienceData.languages.length > 0">
      <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <b-card-title>
            {{$t('instagram.mainLanguages')}}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <component
            :is="apex_charts"
            class="cursor-pointer"
            type="bar"
            height="300"
            :options="chartOptions.languages"
            :series="series.languages"
          ></component>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-md-6 col-lg-4'" v-if="audienceData.ethnicity.length > 0">
      <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <b-card-title>
            {{$t('instagram.ethnicities')}}

          </b-card-title>
        </b-card-header>
        <b-card-body>
          <component
            :is="apex_charts"
            class="cursor-pointer"
            type="bar"
            height="300"
            :options="chartOptions.ethnicity"
            :series="series.ethnicity"
          ></component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import { getLanguage } from '@/libs/utils/others'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    audienceData: {
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chartOptions: {
        type: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#ea5455',
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.getCategories(this.getTypeData(this.audienceData.type), 'name')
          },
          yaxis: {
            show: true,
            labels: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
        ethnicity: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#4be3ac',
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.getCategories(this.audienceData.ethnicity, 'name')
          },
          yaxis: {
            show: true,
            labels: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
        languages: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#6c84e0',
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.getNames(this.audienceData.languages)
          },
          yaxis: {
            show: true,
            labels: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        }
      },
      series: {
        ethnicity: [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.ethnicity)}],
        languages: [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.languages)}],
        type: [{name: 'porcentaje',data: this.getSeries(this.getTypeData(this.audienceData.type))}]
      },
      apex_charts: null,
    }
  },
  methods: {
    getNames() {
      const names = [];
      this.audienceData.languages.map(lan => {
        names.push(getLanguage(lan.code));
      })
      return names;
    },
    getSeries (data) {
      return data.map(item => {
        return item.value
      })
    },
    getCategories (data, key) {
      return data.map(item => {
        return item[key]
      })
    },
    getTypeData (data) {
      const keys = Object.keys(data)
      const obj = []
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index] === 'infs' ? 'Influencer' : keys[index] === 'mass' ? 'Masivo' : keys[index] === 'susp' ? 'Sospechoso' : 'Real'
        obj.push({name: key, value: data[keys[index]]})
      }
      return obj.sort((a, b) => { (a > b) ? 1 : -1 })
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  watch: {
    '$i18n.locale'() {
      this.series.ethnicity =  [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.ethnicity)}]
      this.series.languages = [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.languages)}]
    }
  },
}
</script>
